import React from 'react';
import { FieldExtensionComponentProps, useTemplateSecrets } from '@backstage/plugin-scaffolder-react';
import FormControl from '@material-ui/core/FormControl';
import { FormHelperText, Input, InputLabel } from '@material-ui/core';
import { gitlabAuthApiRef, useApi } from '@backstage/core-plugin-api';
import useAsync from 'react-use/lib/useAsync';

export const GitLabToken = ({
    name,
    onChange,
    schema: { title, description },
    rawErrors,
    required,
    formData,
}: FieldExtensionComponentProps<string>) => {
  const gitLabAuth = useApi(gitlabAuthApiRef);
  const { setSecrets, secrets } = useTemplateSecrets();

  useAsync(async () => {
    const token = await gitLabAuth.getAccessToken(['write_repository']);
    onChange(Array(token.length).fill('*').join(''));
    setSecrets({ [name]: token });
  })

  return (
      <FormControl
      margin="normal"
      required={required}
      error={rawErrors?.length > 0 && !formData}
      aria-readonly={true}
      >
      <InputLabel htmlFor={title}>{title}</InputLabel>
      <Input
        id={title}
        aria-describedby={title}
        value={Array(secrets[name]?.length ?? 0).fill('*').join('')}
        type="password"
        autoComplete="off"
      />
      <FormHelperText id={`${title}-helper`}>
        {description}
      </FormHelperText>
    </FormControl>
  );
};